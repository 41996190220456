import React from "react";

let isRead = (user, article) => {
    if(user.articles) {
        return user.articles.some((articleObj) => {
            return (articleObj._id === article._id && articleObj.readed);
        });
    } return false;
};

let calculCircle = (articles, user, min, max) => {

    let self = this;

    if(articles) {

        let nbrArticles = articles.length;

        let nbrReaded = articles.reduce((acc, val) => {
            return isRead(user, val) === false ? acc : acc + 1;
        }, 0);

        let diff = nbrReaded / nbrArticles;
        let differentiel = min - max;

        return min - (diff * differentiel);
    }

    return min;

};

export class SvgCircleOuter extends React.PureComponent {
    render() {

        const { articles, user, category, min, max } = this.props;

        const style = {
            "strokeDashoffset": calculCircle(articles, user, min, max),
            "transition": "all 1s ease",
            "stroke": '#1FCE7C'
        };

        return (
            <SvgCircle category={ category } style={ style } datas={ this.props.datas }/>
        )
    }
}

export class SvgCircle extends React.PureComponent {
    render() {
        const { className, category } = this.props;

        let height = 30, width = 30;

        if(this.props.datas) {
            if (this.props.datas.height) {
                height = this.props.datas.height;
            }

            if (this.props.datas.width) {
                width = this.props.datas.width;
            }
        }

        return (
            <svg className="category-circle" height={ height } width={ width } viewBox="0 0 32 32">
                <g>
                    <circle cx="15" cy="15" r="15" style={ this.props.style } />
                    <circle cx="10" cy="10" r="10" style={ { fill: '#1FCE7C' } } />
                    <text x="50%" y="50%" textAnchor="middle" fill="#fff" dominantBaseline="center">
                        { category.number }
                    </text>
                </g>
            </svg>
        )
    }
}
