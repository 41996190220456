import GSAPInit from "../../../Animations/GSAP";

class GsapComponent {

    couvertureArticlesEnter= (gsap, target) => {
        if(target === null || !gsap) return;

        const timeline = new gsap.TimelineMax();

        timeline
            .fromTo(target, 0.6, {
                opacity: 0,
                y: 0,
            }, {
                opacity: 1,
                y: 0,
            }, 0.3)
    };
    couvertureArticlesLeave= (gsap, target) => {
        if(target === null || !gsap) return;

        const timeline = new gsap.TimelineMax();

        timeline
            .fromTo(target, 0.3, {
                opacity: 1
            }, {
                opacity: 0
            })

    };

}

let gsap = new GsapComponent();
let gsapInit = new GSAPInit();

export default {
    couvertureArticlesEnter: (target) => {
        gsapInit.importGSAP().then((module) => {
            gsap.couvertureArticlesEnter(module, target)
        });
    },
    couvertureArticlesLeave: (target) => {
        gsapInit.importGSAP().then((module) => {
            gsap.couvertureArticlesLeave(module, target)
        });
    },
};
