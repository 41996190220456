export default class GSAPInit {

    constructor() {
        this.gsap = null;
    }

    async importGSAP() {

        if (typeof window !== "undefined") {
            if(this.gsap) return this.gsap;
            this.gsap = await import('gsap');
            return this.gsap;
        } else {
            return this.gsap;
        }
    }

}
