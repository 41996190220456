import React, { Component } from 'react';
import PageView from "./views/PageView";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Query, withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { GET_ALL, GET_CURRENT_NUMERO } from "../../../../Queries";
import { CurrentNumero } from "../Numero/Numero";

class Page extends Component {

    render() {
        let slug = this.props.match.url.replace('/', '');

        return(
            <Query query={ GET_ALL } variables={{
                magazine_slug: this.context.magazine.slug,
                offset: 0
            }} fetchPolicy="cache-first">
                {({loading, error, data, updateQuery, client, networkStatus}) => {

                    const numeros = data.datas.numeros;

                    return(
                        <Query query={ GET_CURRENT_NUMERO } fetchPolicy="cache-only">
                            {({loading, error, data, client}) => {

                                let numero;

                                numero = numeros[0];
                                if(data.currentNumero) numero = data.currentNumero;

                                return[
                                    <CurrentNumero
                                        key={ 1 } numeros={ numeros }
                                        currentNumero={ numero }
                                        client={ client } datas={ data.datas }
                                        magazine_slug={ this.context.magazine.slug }
                                    />,
                                    <PageView
                                        match={ this.props.match }
                                        toggleMenu={ this.props.toggleMenu }
                                        menuShow={ this.props.menuShow }
                                        magazine_slug={ this.context.magazine.slug }
                                        slug={ slug }
                                        client={ client }
                                    />
                                ];


                            }}
                        </Query>
                    );

                }}
            </Query>
        );
    }

}

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        captureMenuClick: (value) => {
            dispatch({
                type: "CLICK_MENU",
                value: value
            })
        }
    };
};

Page.contextTypes = {
    magazine: PropTypes.object,
    resetCache: PropTypes.bool
};

// export default connect(mapStateToProps, mapDispatchToProps)(Numero);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Page)));
