import React from 'react';

import { Link } from "react-router-dom";
import classNames from "classnames";
import { SettingsContext } from "../../../../Magazine/MagazineSettings";

import "../style/couverture.scss";

function truncate(input, length) {
    if (input.length > length)
        return input.substring(0, length) + '...';
    else
        return input;
}

const ProgressiveContainer = ({ src, loading }) => {

    const classes = classNames({
        'ready': loading,
        'inner-head': true
    });

    return(
        <div className={ classes }>
            <div className="fx-img" />
            {src && <img src={ src } alt='an image' />}
        </div>
    )
};

const ArticleCouverture = ({ article, numero, firstRead, fonts, captureStartRead, captureCouvRead }) => {

    if(article.slug){
        return(
            <div className="article-couverture">

                <div className="inner">

                    <Link to={`/${numero.slug}/${article.category.slug}/${article.slug}`} onClick={ captureCouvRead }>

                        <span className="chapitre" style={ fonts.family2 } data-text="true">
                            <span className="number">{ article.category.number }</span>
                            { article.category.title }
                        </span>

                        <h2 style={ fonts.family1 } data-text="true">{article.title}</h2>
                        <p>{article.description}</p>

                    </Link>

                </div>

                <Link to={`/${numero.slug}/${firstRead.category.slug}/${firstRead.slug}`}
                      className="start-btn" style={ fonts.family1 } data-text="true" onClick={ captureStartRead }>
                        Commencer la lecture
                </Link>

            </div>
        )
    }

    return null
};

const CouvertureView = ({ user, magazine, numero, match, couverture, firstRead, heightCouverture, captureStartRead, captureCouvRead, captureEntreeRead, articles }) => {

    const selectedCouverture = couverture.firstArticle;

    if(selectedCouverture)
        return (
            <SettingsContext.Consumer>
                {({ fonts, logo }) => {

                    return(
                        <div className="couverture">
                            {/*<FrameView height={"78vh"} magazine={ magazine } numero={ numero } fonts={ fonts } logo={ logo }>*/}
                                {/*{numero.articles &&*/}
                                {/*<ArticleCouverture*/}
                                    {/*article={ selectedCouverture }*/}
                                    {/*numero={ numero }*/}
                                    {/*firstRead={ firstRead }*/}
                                    {/*logo={ logo }*/}
                                    {/*fonts={ fonts }*/}
                                    {/*captureStartRead={ captureStartRead }*/}
                                    {/*captureCouvRead={ captureCouvRead }*/}
                                {/*/>*/}
                                {/*}*/}
                            {/*</FrameView>*/}
                            <div className="chapitres">
                                {numero.articles &&
                                <ChapitreView
                                    categories={numero.categories}
                                    numero={ numero }
                                    user={ user }
                                    articles={ couverture.others }
                                    fonts={ fonts }
                                    captureEntreeRead={ captureEntreeRead }
                                    allArticles={ articles }
                                />
                                }
                            </div>
                        </div>
                    )
                }}
            </SettingsContext.Consumer>

        );

    return null;
};

export default CouvertureView;

const getCouverture = ( categories, numero ) => {

    const firstArticle = numero.articles.filter((article) => {
        return article.category._id === categories[0]._id
    })[0];

    if (firstArticle) return firstArticle;

};

const ChapitreView = ({ user, categories, numero, articles, fonts, captureEntreeRead, allArticles }) => {

    let chapitre = (article) => {
        return categories.find(( category ) => category._id === article.category._id);
    };

    let getArticlesByCategory = (articles, category) => {
        return articles.filter((article) => article.category._id === category._id);
    };

    let calculCircle = (articles, user) => {

        let self = this;

        let min = 250; // no read
        let max = 119; // all read

        if(articles) {
            let nbrArticles = articles.length;

            let nbrReaded = articles.reduce((acc, val) => {
                return isRead(user, val) === false ? acc : acc + 1;
            }, 0);

            let diff = nbrReaded / nbrArticles;
            let differentiel = min - max;

            return min - (diff * differentiel);
        }

        return min;

    };

    let isRead = (user, article) => {
        if(user.articles) {
            return user.articles.some((articleObj) => {
                return (articleObj._id === article._id && articleObj.readed);
            });
        } return false;
    };

    let isLastRead = (user, articles) => {
        if(user.lastArticlesRead) {

            return articles.some((articleObj) => {
                return user.lastArticlesRead.find((obj) => {
                    return (articleObj._id === obj._id);
                });
            });

        } return false;
    };

    let articlesAlreadyReadInCategory = (user, categoryArticles) => {
        if(user.articles) {
            return categoryArticles.some((article) => {
                return user.articles.some((articleObj) => {
                    return (articleObj._id === article._id && articleObj.readed);
                });
            });
        } return false;
    };

    let getColorCategory = (article) => {
        let chapitreObj = chapitre(article);
        let chapitreArticles = getArticlesByCategory(allArticles, chapitreObj);

        let percentageRead = calculCircle(chapitreArticles, user);
        let couleurRead = "#c3c3c3";

        if(percentageRead === 119){
            couleurRead = "#4EC575";
        }

        if(isLastRead(user, chapitreArticles) ||
            (articlesAlreadyReadInCategory(user, chapitreArticles) && percentageRead !== 119)){
            couleurRead = "#257FBC";
        }

        return couleurRead;
    };

    return (
        <div>
            {articles.map((article, index) => {
                let chap = chapitre(article);

                if (article) {
                    if(!chap) return null;

                    let readed = isRead(user, article);

                    let classes = classNames({
                        chapitre: true,
                        readed: isRead(user, article),
                    });

                    return (
                        <div className={ classes } key={ index } data-text="true">
                            <Link to={`/${numero.slug}/${chapitre(article).slug}/${article.slug}`}
                                  onClick={ () => captureEntreeRead(index + 1) }>
                                <span className="chapitre-titre" style={ fonts.family2 } data-text="true">
                                    <span className="number"
                                          style={{ backgroundColor: getColorCategory(article) }}>
                                            { chapitre(article).number }</span>
                                    { chapitre(article).title }
                                </span>
                                <span className="title" data-text="true" style={ fonts.family3 }>{ truncate(article.title, 58) }</span>
                                <div className="outer-i">
                                    <div className="time" data-text="true" style={ fonts.family1 }>
                                        <i className="icon-time" />
                                        <span className="inner">
                                            { article.timeForRead }mn
                                        </span>
                                    </div>
                                    {readed &&
                                        <div className="status" data-text="true" style={ fonts.family1 }>
                                            <i className="ico-generia-lu" />
                                            <span className="inner">
                                                Lu
                                            </span>
                                        </div>
                                    }
                                    {!readed &&
                                    <div className="status" data-text="true" style={ fonts.family1 }>
                                        <i className="ico-generia-non_lu" />
                                        <span className="inner">
                                                Non lu
                                            </span>
                                    </div>
                                    }
                                </div>
                            </Link>
                        </div>
                    );
                } return null
            })}
        </div>
    );

};
