import React, { Component } from 'react';
import {GET_ALL, GET_ARTICLE, GET_CURRENT_NUMERO, GET_PREVIEW_ARTICLE} from "../../../../Queries";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import { ArticleContentView } from "./ArticleContentView";

class ArticleContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            getPreviewArticle: null,
            articles: null,
            currentArticle: null,
            previewLoading: true
        }

    }

    getArticleToArticles(articles) {
        return articles.filter((article) => article.slug === this.props.match.params.slug)[0];
    }

    getArticleWithQuery(articles, numero) {

        let article = articles.filter((article) => article.slug === this.props.match.params.slug)[0];

        if(!article) {
            this.props.history.push("/" + numero.slug);
        }

        return article;

    }

    isPreview() {
        return this.props.location.search;
    }

    setCurrentArticle(currentArticle) {
        if(!this.state.currentArticle){
            this.setState({
                currentArticle
            })
        }
    }

    render() { let that = this;

        return (
            <Query query={ GET_CURRENT_NUMERO }>
                {({loading, error, data, client, networkStatus}) => {

                    if (loading) return null;
                    if (error) return `Error! ${error.message}`;

                    let article = this.getArticleWithQuery(data.currentNumero.articles, data.currentNumero);

                    return(
                        <Query query={ GET_ARTICLE }
                               variables={{ magazine_slug: this.context.magazine.slug, article_id: article._id }} fetchPolicy="cache-first">
                            {({ loading, error, data, networkStatus }) => {

                                if (loading) return "";
                                if (error) return `Error! ${error.message}`;

                                if(data.getArticle) {
                                    let articleObj = data.getArticle;

                                    if(this.isPreview()) {
                                        that.setCurrentArticle(data.getArticle);

                                        console.log("baam => ", this.props.previewArticle);

                                        if (this.props.previewLoading) {
                                            return null
                                        }

                                        (this.props.previewArticle) ? articleObj =
                                            this.props.previewArticle : articleObj = data.getArticle
                                    }

                                    return (
                                        <ArticleContentView
                                            article={ articleObj }
                                            numero={ data.currentNumero } fonts={this.props.fonts}
                                            match={ this.props.match }
                                        />
                                    );
                                } return null;

                            }}
                        </Query>
                    )
                }}
            </Query>
        )
    }

}

ArticleContent.contextTypes = {
    magazine: PropTypes.object
};

const mapStateToProps = (state, props) => {

    return {
        params: props.match.params,
        previewLoading: state.preview.previewLoading,
        previewArticle: state.preview.previewArticle
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(ArticleContent)));
