import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { SettingsContext } from "../Magazine/MagazineSettings";

import "./InternetExplorer.scss";
import ReactDOM from "react-dom";

class InternetExplorer extends Component {

    isIE() {
        let ua = window.navigator.userAgent;
        let msie = ua.indexOf("MSIE ");
        return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    }

    render() {
        if(typeof window === "undefined") return null;

        if(this.isIE()){
            return ReactDOM.createPortal(
                <InternetExplorerView />, document.getElementById("ie-support-outer")
            );
        } return null;
    }
}

export default InternetExplorer;


class InternetExplorerView extends Component {


    render() {
        return(
            <SettingsContext.Consumer>
                {({ fonts, logo }) => {
                    return (
                        <div className="ie-support" style={ fonts.family4 }>
                            <div className="inner">
                                <div className="logo">
                                    <img src={ logo.horizontal } alt=""/>
                                </div>
                                <p>Vous naviguez actuellement sur Internet Explorer. Pour une meilleure expérience et pour des raisons de sécurité, nous vous recommandons d'utiliser un navigateur plus récent comme <a href="https://www.google.com/intl/fr_fr/chrome/" target="_blank">Google Chrome</a>, <a href="https://www.mozilla.org/fr/" target="_blank">Mozilla Firefox</a> ou <a href="https://www.microsoft.com/fr-fr/windows/microsoft-edge" target="_blank">Microsoft Edge</a>.</p>
                            </div>
                        </div>
                    )

                }}
            </SettingsContext.Consumer>
        )
    }


}
