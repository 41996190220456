import { Component } from "react";
import * as React from "react";

let Lightbox;

class ImageComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            photoIndex: this.props.index,
            isOpen: false,
            isLight: false
        };

    }

    componentDidMount() {
        import('react-image-lightbox').then((module) => {
            Lightbox = module.default;
            this.setState({
                isLight: true
            });
        });
    }

    onClickHandler = (event) => {
        if (this.props.isDragging) {
            event.preventDefault();
        } else {
            this.setState({ isOpen: true })
        }
    };

    openLightbox = () => {
        this.setState({ isOpen: true })
    };

    render() { const { isOpen, photoIndex, isLight } = this.state; const { images } = this.props;
        return(
            <div className="component component-image">
                <img src={ images[photoIndex].url } alt={ images[photoIndex].legend } onClick={ this.onClickHandler }  />
                {isOpen && isLight && (
                    <Lightbox
                        mainSrc={images[photoIndex].url_full }
                        nextSrc={images[(photoIndex + 1) % images.length].url_full}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length].url_full }
                        imageTitle={ images[photoIndex].legend }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
                {images[photoIndex].legend &&
                    <figcaption style={ this.props.fonts.family4 }>{ images[photoIndex].legend }</figcaption>
                }
            </div>
        )
    }
}

export default ImageComponent;
