import GSAPInit from "../../../Animations/GSAP";

class GsapComponent {

    ArticleEnter= (gsap, target) => {
        if(target === null || !gsap) return;

        const timeline = new gsap.TimelineMax();

        timeline
            .fromTo(target, 0.4, {
                autoAlpha: 0,
            }, {
                autoAlpha: 1,
            })
    };
    ArticleLeave= (gsap, target) => {
        if(target === null || !gsap) return;

        const timeline = new gsap.TimelineMax();

        timeline
            .fromTo(target, 0.4, {
                autoAlpha: 1
            }, {
                autoAlpha: 0
            })

    };

}

let gsap = new GsapComponent();
let gsapInit = new GSAPInit();

export default {
    ArticleEnter: (target) => {
        gsapInit.importGSAP().then((module) => {
            gsap.ArticleEnter(module, target)
        });
    },
    ArticleLeave: (target) => {
        gsapInit.importGSAP().then((module) => {
            gsap.ArticleLeave(module, target)
        });
    },
};
