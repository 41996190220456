import React from 'react';
import LateralBar from "../components/LateralBar";
import Main from "../components/Main";
import Metas from "../../Metas/Metas";

import "../style/numero.scss";
import "../../Menu/style/menu.scss";

const NumeroView = ({ id, match, currentNumero, toggleMenu, menuShow, magazine }) => {

    return (
        <div className="numero">
            <Metas article={ currentNumero } magazine={ magazine } />
            <LateralBar toggleMenu={ toggleMenu } menuShow={ menuShow } />
            <Main match={ match } menuShow={ menuShow } toggleMenu={ toggleMenu } />
        </div>
    )
};

export default NumeroView;
