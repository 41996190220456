import GSAPInit from "../../../Animations/GSAP";

class GsapComponent {

    onEnter(gsap, target) {

        if(target === null || !gsap) return;
        if(!target) return;

        gsap.TweenMax
            .to(target, 0.3, { opacity: 1, ease: gsap.Power3.easeInOut });
    }

    onLeave(gsap, target) {
        if(target === null || !gsap) return;
        if(!target) return;

        gsap.TweenMax
            .to(target, 0.2, { opacity: 0, ease: gsap.Power3.easeInOut });
    }

}

let gsap = new GsapComponent();
let gsapInit = new GSAPInit();

export default {
    onEnter: (target) => {
        gsapInit.importGSAP().then((module) => {
            gsap.onEnter(module, target)
        });
    },
    onLeave: (target) => {
        gsapInit.importGSAP().then((module) => {
            gsap.onLeave(module, target)
        });
    },
}
