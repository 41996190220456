import { Component } from "react";
import * as React from "react";
import { ContactForm } from "./ContactForm";
import { Content } from "../../Article/components/Content";

export class ContactFormView extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        return(
            <div className="contact-form-page-outer">
                <div data-text="true" className="title-page">
                    <h1 style={ this.props.fonts.family1 }>
                        { this.props.page.title }
                    </h1>
                </div>
                {this.props.page.custom.introduction &&
                    <div className="infos-principales"
                         style={this.props.fonts.family3}
                         dangerouslySetInnerHTML={{__html: this.props.page.custom.introduction}}>
                    </div>
                }
                {this.props.page.custom.subtitle &&
                    <div data-text="true" className="subtitle-page">
                        <h2 style={this.props.fonts.family1}>
                            {this.props.page.custom.subtitle}
                        </h2>
                    </div>
                }
                <ContactForm client={ this.props.client} magazine_slug={ this.props.magazine_slug }
                             fonts={ this.props.fonts }/>
                <Content
                    article={ this.props.page }
                    match={ this.props.match }
                    isPage={ true }
                />
            </div>
        )
    }


}
