import GSAPInit from "../../../Animations/GSAP";

export class animationsState {

    constructor(props){
        this.gsap = null;
        this.timeline = null;

        this.state = {
            current: null
        }
    }

    async _init() { let self = this;
        let gsapInit = new GSAPInit();
        this.gsap = await gsapInit.importGSAP();
        return true;
    }

    initAnimationImage() {
        this.timeline = new this.gsap.TimelineMax();
    }

    animationImage(imageTarget1, imageTarget2, speed) { let self = this;

        let img = new Image();

        console.log("animationImage", imageTarget1, imageTarget2);

        let fromTo = null;
        self.current = imageTarget1;

        img.onload = function () {
            console.log("onload image");

            console.log("current => ", self.current);
            console.log("imageTarget1 => ", imageTarget1);

            if(self.current === imageTarget1) {
                self.timeline.kill();
                /*self.timeline
                    .fromTo(imageTarget1, speed, {autoAlpha: 0}, {autoAlpha: 1}, "+=3")*/
                //.fromTo(".fx-img", 0.3, { autoAlpha: 0 }, { autoAlpha: 1 }, "-=0.6");
            }
        };

        img.src = imageTarget2.src;

        console.log("timeline");

        self.timeline
            .kill()
            //.fromTo(imageTarget2, 3, { autoAlpha: 1 }, { autoAlpha: 0 })
            //.fromTo(imageTarget1, 3, { autoAlpha: 1 }, { autoAlpha: 0 }, "-=" + 3)
            //.fromTo(".fx-img", 0.1, { autoAlpha: 1 }, { autoAlpha: 0 }, "-=0.5")
            /*.fromTo(document.querySelector(".article-couverture .inner"), 0.7, {
                autoAlpha: 0,rotation: 0.0001, ease: this.gsap.Power3.easeInOut
            }, {
                autoAlpha: 1,rotation: 0.0001, ease: this.gsap.Power3.easeInOut
            }, "-=0.35");*/

    }



}
