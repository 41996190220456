import React from 'react';
import { Content } from "../Article/components/Content";

export const ArticleContentView = ({ match, article, numero, fonts }) => {
    return(
        <section className="inner-main">
            <Content match={ match } article={ article }/>
        </section>
    )
};
