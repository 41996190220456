import React, { Component } from 'react';
import AnimBackground2 from "./AnimBackground2";
import animations from "../animations";
import { Link } from "react-router-dom";

import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import { NumeroMenu } from "../../Numero/components/views/NumeroMenu";

import "../style/menu.scss";
import classNames from "classnames";
import Loadable from "react-loadable";

let BodyScrollModule = null;

function truncate(input, length) {
    if (input.length > length)
        return input.substring(0, length) + '...';
    else
        return input;
}

const Loading = () => null;

const SvgCircleOuter = Loadable({
    loader: () => import('./SvgCircleOuter'),
    loading: Loading
});

function waitAnimationEnd(fn, duration)  {
    setTimeout(() => {
        fn();
    }, duration);
}

class ChapitreMenu extends Component {

    constructor(props){
        super(props);

        this.ref = "";

        if(this.props.articles.length > 0)
            this.state = {
                backgroundImage: (this.props.articles[0].wallpaper.formats.menu) ?
                    this.props.articles[0].wallpaper.formats.menu : this.props.logo.horizontal_blanc,
                backgroundImageHover: (this.props.articles[0].wallpaper.formats.menu) ?
                    this.props.articles[0].wallpaper.formats.menu : this.props.logo.horizontal_blanc,
                noImg: !this.props.articles[0].wallpaper.formats.menu
            };
        else
            this.state = {
                backgroundImage: "",
                backgroundImageHover: "",
                noImg: true
            };
    }

    changeBackgroundHover = (article) => {
        this.setState({
            backgroundImageHover: (article.wallpaper.formats.menu) ?
                article.wallpaper.formats.menu : this.props.logo.horizontal_blanc,
            noImg: !article.wallpaper.formats.menu
        });
    };

    changeBackgroundOut = (article) => {
        this.setState({
            backgroundImageHover: this.state.backgroundImage,
            noImg: !article.wallpaper.formats.menu
        });
    };

    isLastRead = (user, articles) => {
        if(user.lastArticlesRead) {

            return articles.some((articleObj) => {
                return user.lastArticlesRead.find((obj) => {
                    return (articleObj._id === obj._id);
                });
            });

        } return false;
    };

    articlesAlreadyReadInCategory = (user, categoryArticles) => {
        if(user.articles) {
            return categoryArticles.some((article) => {
                return user.articles.some((articleObj) => {
                    return (articleObj._id === article._id && articleObj.readed);
                });
            });
        } return false;
    };

    isRead = (user, article) => {
        if(user.articles) {
            return user.articles.some((articleObj) => {
                return (articleObj._id === article._id && articleObj.readed);
            });
        } return false;
    };

    nbrReadedInCat = (user, articles) => { let self = this;
        return articles.reduce((acc, val) => {
            return self.isRead(user, val) === false ? acc : acc + 1;
        }, 0);
    };

    calculCircle = (articles, user) => {

        let self = this;

        let min = 250; // no read
        let max = 119; // all read

        if(articles) {

            let nbrArticles = articles.length;

            let nbrReaded = articles.reduce((acc, val) => {
                return self.isRead(user, val) === false ? acc : acc + 1;
            }, 0);

            let diff = nbrReaded / nbrArticles;
            let differentiel = min - max;

            return min - (diff * differentiel);
        }

        return min;

    };

    render() { let that = this; const { articles, category, fonts, numero, toggleMenu, user } = this.props;

        if(this.props.articles)
        {

            const classesLi = classNames({
                'chapitre': true,
                'no-img': this.state.noImg
            });

            const classes = classNames({
                'no-read-yet': (that.nbrReadedInCat(user, articles) < articles.length),
                'readed': (that.nbrReadedInCat(user, articles) === articles.length),
                'chapitre-title': true,
                'no-img': this.state.noImg
            });

            let lastRead = that.isLastRead(user, articles);
            let alreadyRead = that.articlesAlreadyReadInCategory(user, articles);

            //alert(alreadyRead);

            return(
                <li key={ category._id } className={ classesLi }>
                    <div className="inner">
                        <div className="categories" style={ fonts.family1 }>
                            <div className="chapitre-wallpaper" style={ { backgroundImage : `url(${ this.state.backgroundImageHover })` } }/>
                            <div className="timeline-circle-category" style={ fonts.family2 }>
                                { lastRead &&
                                    <span className="reading-outer">
                                        <span className="reading"
                                              style={ { backgroundColor: '#257FBC' }}>En cours</span>
                                    </span>
                                }
                                <SvgCircleOuter
                                    calculCircle={ that.calculCircle }
                                    category={ category }
                                    user={ user }
                                    articles={ articles }
                                    isLastRead={ lastRead }
                                    alreadyRead={ alreadyRead }
                                />
                            </div>
                        </div>
                        <ul className="articles-outer">
                            <div className="articles">
                                <span className={ classes }>{ category.title}</span>
                                <ArticlesListing {...this.props} isRead={ this.isRead }
                                                 changeBackgroundOut={ this.changeBackgroundOut }
                                                 changeBackgroundHover={ this.changeBackgroundHover }
                                                 nbrReadedInCat={ this.nbrReadedInCat }
                                />
                            </div>
                        </ul>
                    </div>
                </li>
            )
        }

    }


}

const ArticlesListing = ({ articles, user, fonts, numero, category, toggleMenu, isRead, changeBackgroundHover, changeBackgroundOut, nbrReadedInCat }) => {

    let findSubcategory = articles.find((article) => {
        return article.custom.sous_chapitre
    });

    let haveSubcategory = (article) => {
        return article.custom.sous_chapitre
    };

    let sortFn = (article1, article2) => {
        if (article1.custom.sous_chapitre < article2.custom.sous_chapitre)
            return -1;
        if (article1.custom.sous_chapitre > article2.custom.sous_chapitre)
            return 1;
        return 0;
    };

    let sortArticles = (articles) =>
        articles.sort((a, b) => { return (a.positionChapitre - b.positionChapitre) });

    let renderArticles = (articlesArr) => {
        return articlesArr.map(article => {

            let isReadArticle = isRead(user, article);

            const classes = classNames({
                'done': isReadArticle,
            });

            const iconClasses = classNames({
                'ico-generia-lu': isReadArticle,
                'ico-generia-non_lu': !isReadArticle
            });

            return (
                <li key={article._id} style={fonts.family3} onMouseOver={() => changeBackgroundHover(article)}
                    onMouseLeave={() => changeBackgroundOut(articles[0])}>
                    <Link to={`/${numero.slug}/${category.slug}/${article.slug}`}
                          onClick={toggleMenu} key={article._id} className={classes}
                    >
                        <i className={iconClasses}/>
                        <span dangerouslySetInnerHTML={{ __html: article.title }} />
                    </Link>
                </li>
            );

        });
    };

    if(!findSubcategory){
        return renderArticles(articles);
    } else {
        let sort = articles.sort(sortFn);
        let sousChapitresArr = [];
        let isBefore = true;
        let articlesNoSubcategories = {
            before: [],
            after: []
        };

        const classesSubcategory = classNames({
            'readed': (nbrReadedInCat(user, articles) === articles.length),
            'subcategory-title': true
        });

        articles.forEach(function (article) {

            if(article.custom.sous_chapitre) {
                isBefore = false;
                if (sousChapitresArr.length < 1) {
                    sousChapitresArr.push(article.custom.sous_chapitre);
                }

                if (!sousChapitresArr.find((sous_chapitre) => (sous_chapitre.slug === article.custom.sous_chapitre.slug))) {
                    sousChapitresArr.push(article.custom.sous_chapitre)
                }
            } else {
                if(isBefore) {
                    articlesNoSubcategories.before.push(article);
                } else {
                    articlesNoSubcategories.after.push(article);
                }
            }

        });

        return[
            <div key={ 2 } className="articles-before-list">
                {renderArticles(articlesNoSubcategories.before)}
            </div>,
            <div className="subcategories" key={ 1 }>
                {sousChapitresArr.map((sous_chapitre) => {
                    let articlesSub = articles.filter((article) =>
                    {
                        return (article.custom.sous_chapitre && article.custom.sous_chapitre.slug === sous_chapitre.slug)
                    });

                    return(
                        <div className="subcategory" key={ sous_chapitre.id }>
                            <span className={ classesSubcategory }
                                  dangerouslySetInnerHTML={{ __html: sous_chapitre.title }} />
                            {articlesSub && renderArticles(articlesSub)}
                        </div>
                    )
                })}
            </div>,
            <div key={ 3 } className="articles-after-list">
                {renderArticles(articlesNoSubcategories.after)}
            </div>
        ];
    }

};

class MenuView extends Component {

    constructor(props){
        super(props);

        this.ref = "";
        this.footerRef = "";

        this.state = {
            render: false,
            animBackground: false,
            numeroMenu: null,
            bodyScrollModuleLoaded: false
        };

    }

    /*static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.show !== prevState.show)
            return {
                show: nextProps.show
            };

        return null;
    }*/

    componentWillUnmount() { let self = this;
        animations.setOnEnter(this.ref);
        if(self.state.bodyScrollModuleLoaded) {
            console.log("BodyScrollModule => ", BodyScrollModule);

            BodyScrollModule.clearAllBodyScrollLocks();
        }
        // animations.footerMenuLeave(this.footerRef);
    }

    animationMenu(show) {

        if(show){

            setTimeout(() => {
                animations.setOnEnter(this.ref);
                animations.onEnter(this.ref);
                animations.footerMenuEnter(this.footerRef);
            }, 10);

        } else {

            this.setState({
                animBackground: !this.state.animBackground,
            });

            animations.setOnEnter(this.ref);
            animations.footerMenuLeave(this.footerRef);

            /*setTimeout(() => {
                this.setState({
                    render: false,
                });
            }, 200);*/

        }
    }

    setReference = (ref) => {
        this.ref = ref;
    };

    setFooterReference = (ref) => {
        this.footerRef = ref;
    };

    shouldComponentUpdate(prevProps) {
        return (prevProps.show !== this.props.show)
    }

    componentDidUpdate(prevProps, prevState) { let self = this;

        if(prevState.animBackgroundAnimation !== true) {

            if(this.props.show) {
                if(self.state.bodyScrollModuleLoaded) {
                    if (this.checkWindowWidth()) {
                        BodyScrollModule.disableBodyScroll(this.menuInterfaceMobile);
                    } else {
                        BodyScrollModule.disableBodyScroll(this.menuInterface);
                    }
                }
            } else {
                if(self.state.bodyScrollModuleLoaded) {
                    if (this.checkWindowWidth()) {
                        BodyScrollModule.enableBodyScroll(this.menuInterfaceMobile)
                    } else {
                        BodyScrollModule.enableBodyScroll(this.menuInterface);
                    }
                }
            }

            if (prevProps.show !== this.props.show) {
                this.animationMenu(this.props.show);

                setTimeout(function() {
                    if(document.querySelector(".menu.toggled")) {
                        if (typeof document.querySelector(".menu.toggled").scrollTo !== "undefined") {
                            document.querySelector(".menu.toggled").scrollTo(0, 0);
                            self.menuInterface.scrollTo(0,0);
                        } else {
                            document.querySelector(".menu.toggled").scrollTop = 0;
                            self.menuInterface.scrollTop = 0;
                        }
                    }
                }, 0)

            }
        }

    }

    componentDidMount(){ let numeroMenu;

        import('body-scroll-lock').then((module) => {
            BodyScrollModule = module;
            this.setState({
                bodyScrollModuleLoaded: true
            });
        });

        this.animationMenu(this.props.show, true);
        animations.setOnEnter(this.ref);

        if(this.checkWindowWidth()){
            if(this.props.data.numeros)

                numeroMenu =
                    <NumeroMenu
                        match={ this.props.match }
                        toggleMenu={ this.props.toggleMenu }
                        numeros={ this.props.data.numeros }
                    />
        }

        this.setState({
            show: this.props.show,
            numeroMenu
        });
    }

    getCategories(categories) {

        if(categories) {
            const categoriesArr = categories.slice(0);
            categoriesArr.sort((a, b) => {
                return (a.order - b.order)
            });

            return categoriesArr.slice(0);
        } else {
            // alert("0 categories");
            return null;
        }
    }

    getArticles(articles, category) {
        return articles.filter((article) => article.category._id === category._id);
    }

    openLink = (link, e) => {
        e.preventDefault();
        this.props.toggleMenu(false);
        this.props.history.push(link);
    };

    checkWindowWidth() {
        return window.innerWidth <= 425
    };

    render(){ let that = this;

        if(typeof window === "undefined") return null;

        const classes = classNames({
            'toggled': this.props.menu,
            'menu'   : true
        });

        if(typeof this.props.numero === "undefined") return null;

        return (
            <SettingsContext.Consumer>
                {({ fonts, contact }) => (
                    <div className={ classes } style={ this.state.style } ref={(ref) => this.menuInterfaceMobile = ref }>
                        <AnimBackground2 animation={ this.state.animBackground } />

                        <div className="menu-inner" ref={ this.setReference }>
                            {this.state.numeroMenu}
                            <div className="articles-test" ref={(ref) => this.menuInterface = ref }>
                                <ul>
                                    {that.getCategories(this.props.numero.categories).map((category, index) => {

                                        const articles = that.getArticles(this.props.numero.articles, category);

                                        return(<ChapitreMenu
                                            category={ category }
                                            logo={ this.props.logo }
                                            articles={ articles }
                                            user={ this.props.user }
                                            fonts={ fonts }
                                            toggleMenu={ this.props.toggleMenu }
                                            numero={ this.props.numero }
                                            key={ index }
                                        />);

                                    })}
                                </ul>
                            </div>

                        </div>

                        <div className="footer-menu" ref={ this.setFooterReference }>
                            <div className="inner-footer-menu">
                                <ul style={ fonts.family1 }>
                                    <li>
                                        <Link to={"/credits"}
                                              onClick={(e) => this.openLink("/credits", e) }>
                                            Crédits
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/mentions-legales"}
                                              onClick={(e) => this.openLink("/mentions-legales", e) }>
                                            Mentions légales
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/contact"}
                                              onClick={(e) => this.openLink("/contact", e) }>
                                            Contact
                                        </Link>
                                    </li>
                                    <li>
                                        <div onClick={ this.props.openRGPDPopup }>
                                            Gestion des cookies
                                        </div>
                                    </li>
                                </ul>
                                <div className="credits" style={ fonts.family1 }>
                                    Tous droits réservés Generia - Propulsé par <a href="https://www.ridmi.io" target="_blank">Ridmi</a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </SettingsContext.Consumer>
        )
    }
}

export default MenuView;
