import React, { Component } from 'react';

import "./style/pwa.scss";
import close from './assets/close-button.svg' // relative path to image

export class PWA extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deferredPrompt: null,
            isPWA: false,
        };

    }

    cancelPwa = () => {
        this.setState({
            isPWA: false
        });
    };

    addPwa = () => { let that = this;
        // Show the prompt
        this.state.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        this.state.deferredPrompt.userChoice
            .then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }

                that.setState({
                    isPWA: false,
                    deferredPrompt: null
                });

            });
    };

    componentDidMount() { let that = this;

        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();

            this.setState({
                isPWA: true,
                deferredPrompt: e
            });

        });
    }

    render() {
        if(this.state.isPWA){
            return(
                <div className="add-to-homescreen-block">
                    <div className="title" onClick={ this.addPwa }>
                        Ajouter à l'écran d'accueil
                    </div>
                    <div className="actions">
                        <button className="cancelHomescreen" onClick={ this.cancelPwa }>
                            <img src={ close } alt=""/>
                        </button>
                    </div>
                </div>
            )
        } return null;

    }

}
