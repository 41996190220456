import React, { Component, PureComponent } from 'react';

import animations from "../animations";

export default class AnimBackground2 extends PureComponent {

    constructor(props) {
        super(props);
        this.ref = "";

        this.state = {
            show: false,
        }
    }

    setReference = (ref) => {
        this.ref = ref;
    };

    componentDidMount() {
       animations.backgroundEnter(this.ref);
    }

    componentDidUpdate() {
        animations.backgroundLeave(this.ref);
    }

    render() {
        return (
            <div className="background-menu" ref={ this.setReference } />

            // <svg height="100%" width="100%">
            //     <circle cx="0%" cy="0%" r="0%" stroke="black" strokeWidth="0" fill="#4E49FC" ref={ this.setReference } />
            // </svg>
        )
    }
}
