import React from "react";

export class ArticlesListingSubcategory extends React.Component {

    findSubcategory = (articles) => articles.find((article) => {
        return article.custom.sous_chapitre
    });

    sortFn = (article1, article2) => {
        if (article1.custom.sous_chapitre < article2.custom.sous_chapitre)
            return -1;
        if (article1.custom.sous_chapitre > article2.custom.sous_chapitre)
            return 1;
        return 0;
    };

    render() {
        let { articles, user, fonts, numero, category } = this.props;

        if(!this.findSubcategory(articles)){
            return this.props.children(articles);
        } else {
            let sort = articles.sort(this.sortFn);
            let sousChapitresArr = [];

            let isBefore = true;

            let articlesNoSubcategories = {
                before: [],
                after: []
            };

            articles.forEach(function (article) {
                if(article.custom.sous_chapitre) {
                    isBefore = false;
                    if (sousChapitresArr.length < 1) {
                        sousChapitresArr.push(article.custom.sous_chapitre);
                    }

                    if (!sousChapitresArr.find((sous_chapitre) => (sous_chapitre.slug === article.custom.sous_chapitre.slug))) {
                        sousChapitresArr.push(article.custom.sous_chapitre)
                    }
                } else {
                    if(isBefore) {
                        articlesNoSubcategories.before.push(article);
                    } else {
                        articlesNoSubcategories.after.push(article);
                    }
                }

            });

            return this.props.children(articles, articlesNoSubcategories, sousChapitresArr);
        }

    }
}


export function articlesListingSubcategoryFn(articles, user, fonts, numero, category) {

    let findSubcategory = (articles) => articles.find((article) => {
        return article.custom.sous_chapitre
    });

    let sortFn = (article1, article2) => {
        if (article1.custom.sous_chapitre < article2.custom.sous_chapitre)
            return -1;
        if (article1.custom.sous_chapitre > article2.custom.sous_chapitre)
            return 1;
        return 0;
    };

    if(!findSubcategory(articles)){
        return {
            allArticles: articles
        };
    } else {
        let sort = articles.sort(sortFn);
        let sousChapitresArr = [];

        let isBefore = true;

        let articlesNoSubcategories = {
            before: [],
            after: []
        };

        articles.forEach(function (article) {
            if(article.custom.sous_chapitre) {
                isBefore = false;
                if (sousChapitresArr.length < 1) {
                    sousChapitresArr.push(article.custom.sous_chapitre);
                }

                if (!sousChapitresArr.find((sous_chapitre) => (sous_chapitre.slug === article.custom.sous_chapitre.slug))) {
                    sousChapitresArr.push(article.custom.sous_chapitre)
                }
            } else {
                if(isBefore) {
                    articlesNoSubcategories.before.push(article);
                } else {
                    articlesNoSubcategories.after.push(article);
                }
            }

        });

        return {
            allArticles: articles,
            articlesNoSub: articlesNoSubcategories,
            sousChapitresArr: sousChapitresArr
        };

    }
}


