import classNames from "classnames";
import * as React from "react";

import Carrousel from "../components/Carrousel";
import Image from "../components/Image";
import Chiffres from "../components/Chiffres";
import Text from "../components/Text";
import Title from "./Title";
import Citation from "./Citation";

const generateKey = (kind) => {
    return `${ kind }_${ new Date().getTime() }`;
};

function subChildElements(dynamic, fonts, article, updateMasonry) {

    if(dynamic) {

        return dynamic.map((content, index) => {
            switch (content.kind) {
                case 'title':
                    return (<Title datas={ content }
                                   fonts={ fonts } key={ generateKey(content.id)  } article={ article } />);
                case 'text':
                    return (<Text datas={ content }
                                  fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'citation':
                    return (<Citation datas={ content }
                                      fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'slider':
                    return (<Carrousel datas={ content } updateMasonry={ updateMasonry }
                                       fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'images':
                    return (<Image datas={ content }
                                   fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                case 'chiffres':
                    return (<Chiffres datas={ content } updateMasonry={ updateMasonry }
                                      fonts={ fonts } key={ generateKey(content.id) } article={ article } />);
                default:
                    return null;
            }
        })
    }

    return null;

}

const Breve = ({ style, fonts, datas, article, updateMasonry }) => {

    const classes = classNames({
        'style': datas.content.is_encadre,
        'component': true,
        'component-breve': true
    });

    return(
        <div className={ classes } style={ { borderColor: article.category.couleur } }>
            <div className="inner">
                {datas.content.titre &&
                    <h2 style={fonts.family1}>{datas.content.titre}</h2>
                }
                { subChildElements(datas.content.dynamic, fonts, article, updateMasonry) }
            </div>
        </div>
    )
};

export default Breve;
