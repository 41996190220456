import classNames from "classnames";
import * as React from "react";

const Text = ({ style, fonts, datas }) => {

    const classes = classNames({
        'style': datas.styled,
        'component': true,
        'component-text': true
    });

    return(
        <div className={ classes }>
            <div dangerouslySetInnerHTML={{ __html: datas.text }} />
        </div>
    )
};

export default Text;
