import React, { Component } from 'react';

import HeaderView from "./views/HeaderView";
import {connect} from "react-redux";
import { Query } from "react-apollo";
import {GET_ALL, GET_CURRENT_NUMERO, GET_MENU} from "../../../../../Queries";
import PropTypes from "prop-types";

import { SettingsContext } from "../../../../Magazine/MagazineSettings";
import { withRouter } from "react-router-dom";
import { withApollo } from "react-apollo";

class Header extends Component {

    constructor(props){
        super(props);

        this.state = {
            scrolled: false,
            scrollFX: false,
        };

        this.handleScroll = this.handleScroll.bind(this);

    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() { let self = this;

        const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

        if( scrollTop >= 100){
            if(!this.state.scrolled)
                this.setState({ scrolled: true });
                if(!this.props.scrolled)
                    this.props.headerScrolled(true);
        } else {
            if(this.state.scrolled)
                setTimeout(function() {
                    self.setState({ scrolled: false });
                }, 0);
                if(this.props.scrolled)
                    this.props.headerScrolled(false);
        }

        if( scrollTop >= 539){
            if(!this.state.scrollFX)
                this.setState({ scrollFX: true })
        } else {
            if(this.state.scrollFX)
                this.setState({ scrollFX: false })
        }

    }

    openLinkAndLoading = (link, e) => {
        e.preventDefault();
        this.props.toggleMenu(false);
        this.props.history.push(link);
        //this.props.toggleLoadingOn();
    };

    openLink = (link, e) => {
        e.preventDefault();
        this.props.toggleMenu(false);
        this.props.captureLogoClick();
        this.props.history.push(link);
    };

    render() {

        return(
            <SettingsContext.Consumer>
                {({ fonts, logo }) => {
                    return( <Query query={ GET_ALL } variables={
                        {
                            magazine_slug: this.context.magazine.slug,
                            offset: 0
                        }
                    } fetchPolicy="cache-first">
                        {({loading, error, data, fetchMore}) => {

                            let datas = data.datas;

                            return (
                                <Query query={ GET_MENU }>
                                    {({loading, error, data, client}) => {
                                        let menu = data.getMenu;

                                        return (
                                            <Query query={ GET_CURRENT_NUMERO }>
                                                {({loading, error, data, client}) => {

                                                    if (!data.currentNumero) {
                                                        this.forceUpdate();
                                                    }

                                                    if (loading) return "Loading GET_CURRENT_NUMERO header...";
                                                    if (error) return `Error! ${error.message}`;

                                                    return (
                                                        <HeaderView match={this.props.match}
                                                                    toggleMenu={this.props.toggleMenu} data={datas}
                                                                    menu={ menu }
                                                                    isNoImg={ this.props.isNoImg }
                                                                    openLinkAndLoading={ this.openLinkAndLoading }
                                                                    currentArticles={ this.props.currentArticles }
                                                                    isPage={ this.props.isPage }
                                                                    isSearching={ this.props.search }
                                                                    scrolled={this.state.scrolled}
                                                                    openLink={ this.openLink }
                                                                    menuShow={this.props.menuShow}
                                                                    scrollFX={this.state.scrollFX}
                                                                    user={ this.props.user }
                                                                    fetchMore={fetchMore}
                                                                    client={ this.props.client }
                                                                    magazine={this.context.magazine}
                                                                    currentNumero={data.currentNumero}
                                                                    updateWidthScroll={ this.props.updateWidthScroll }
                                                                    logo={ logo }
                                                                    kindView={ this.props.view }
                                                                    fonts={ fonts }
                                                        />
                                                    )

                                                }}
                                            </Query>
                                        );

                                    }}
                                </Query>
                            );
                        }}
                    </Query> )
                }}
            </SettingsContext.Consumer>
        )
    }


}

const mapStateToProps = (state, props) => {
    return {
        slug: props.match.params.slug,
        scrolled: state.scrolled.scrolled,
        currentArticles: state.user.lastArticlesRead,
        search: state.search.isSearching,
        view: state.view.kind,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        headerScrolled: (value) => {
            dispatch({
                type: "CHANGE_SCROLL",
                value: value
            })
        },
        updateWidthScroll: (value) => {
            dispatch({
                type: "UPDATE_WIDTH_SCROLL",
                value: value
            })
        },
        captureLogoClick: (value) => {
            dispatch({
                type: "CLICK_LOGO"
            })
        },
        isNoImg: (value) => {
            dispatch({
                type: "SET_NO_IMG",
                value
            })
        },
        toggleLoadingOn: () => {
            dispatch({
                type: "LOADING_VIEW_MAIN",
                loading: true
            })
        },
    };
};

Header.contextTypes = {
    magazine: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(Header)));
