import { Component } from "react";
import * as React from "react";

export class Accessibilite extends Component {

    constructor(props){
        super(props);

        this.state = {
            min: 0,
            max: 0,
        }

    }

    minA = () => {

        if(this.state.min <= 1) {

            let current = getComputedStyle(document.documentElement).fontSize;
            document.documentElement.style.fontSize = (parseInt(current, 10) - 2) + "px";

            this.setState({
                min: this.state.min + 1,
                max: this.state.max - 1
            })
        }
    };

    plusA = () => {

        if(this.state.max <= 1) {

            let current = getComputedStyle(document.documentElement).fontSize;
            document.documentElement.style.fontSize = (parseInt(current, 10) + 2) + "px";

            this.setState({
                max: this.state.max + 1,
                min: this.state.min - 1
            })

        }

    };

    render(){

        let family = (this.props.font) ? this.props.font : this.props.fonts.family3;

        return(
            <div className="accessibilite" style={ family }>
                <button onClick={ this.plusA }>A+</button>
                <span>/</span>
                <button onClick={ this.minA }>A-</button>
            </div>
        )

    }

}
