import { Component } from "react";
import PropTypes from "prop-types";
import { GET_ALL } from "../../../Queries";
import { Query } from "react-apollo";
import Numero from "./Numero/Numero";
import * as React from "react";

export class Root extends Component {

    constructor(props){
        super(props);
    }

    render() { let that = this;

        return(
            <Query query={ GET_ALL } variables={{
                magazine_slug: this.context.magazine.slug,
                offset: 0
            }}>
                {({loading, error, data, updateQuery, client, networkStatus}) => {
                    return (<Numero numero={ data.datas.numeros[0] } menuShow={ this.props.menuShow } toggleMenu={ this.props.toggleMenu }/>);
                }}
            </Query>
        )

    }


}

Root.contextTypes = {
    magazine: PropTypes.object
};
